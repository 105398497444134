import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Link from "../UI/Link"
import { PillButton } from "../UI/PillButton"
import { setIsExternalLink } from "../../utils/setIsExternalLink"
import {
  imgColClass,
  imageClass,
  linkColClass,
  loadBtnClass
} from "./ImgAndLinkList.module.scss"

export const fragment = graphql`
  fragment imgAndLinkList on WpPage_Flexlayouts_FlexibleLayouts_ImgAndLinkList {
    listItems {
      image {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              layout: FIXED
              width: 240
              aspectRatio: 1.7778
              placeholder: NONE
            )
          }
        }
      }
      link {
        url
        title
      }
    }
  }
`

const bkgdImageQuery = graphql`
  query bkgdImage {
    bkgdImage: file(relativePath: { eq: "bkgd-dots-optimized.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

const ImgAndLinkList = ({ listItems }) => {
  const bkgdImageData = useStaticQuery(bkgdImageQuery)
  const bkgdImage = getImage(bkgdImageData?.bkgdImage)
  const [numOfItems, setNumOfItems] = useState(6)
  const [itemsToShow, setItemsToShow] = useState([
    ...listItems.slice(0, numOfItems)
  ])
  const [btnDisabled, setbtnDisabled] = useState(listItems.length <= numOfItems)
  const isMorePosts = () => {
    if (listItems.length <= numOfItems) {
      setbtnDisabled(true)
    }
  }

  const loadMoreItems = () => {
    let toAdd = 9
    if (listItems.length > numOfItems + toAdd) {
      setNumOfItems(numOfItems + toAdd)
    } else {
      toAdd = numOfItems + (listItems.length - numOfItems)
      setNumOfItems(numOfItems + toAdd)
    }
  }

  useEffect(() => {
    setItemsToShow([...listItems.slice(0, numOfItems)])
    isMorePosts()
  }, [numOfItems])

  return (
    <div className="bg-pale-grey position-relative h-100 p-0">
      <BgImage image={bkgdImage} style={{ backgroundRepeat: "repeat-y" }}>
        <Container>
          <div className={btnDisabled ? "position-relative h-100 px-0 pt-6 pb-4" : "position-relative h-100 px-0 pt-6"}>
            <Row className={imgColClass}>
              {itemsToShow.map((item, i) => {
                const {
                  image,
                  link: { url, title },
                  linkType
                } = item

                const img = getImage(image?.localFile)
                const isExternal = setIsExternalLink(url)

                return (
                  <Col
                    sm={12}
                    lg={6}
                    className="mb-4"
                    key={`9svhu3oys${i}`}>
                    <Row>
                      <Col xs={6} className={imgColClass}>
                        <GatsbyImage
                          className={imageClass}
                          image={img}
                          alt={image?.altText}
                        />
                      </Col>
                      <Col xs={6} className={`${linkColClass} linkClass`}>
                        <Link
                          classes="text-secondary"
                          link={url}
                          title={title} />
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </div>
          {!btnDisabled && (
            <Row
              className={`${loadBtnClass} d-flex justify-content-center pt-3 pt-lg-5 pb-6`}>
              <Col xs={12} lg={4}>
                <PillButton
                  variant="outline-secondary"
                  outline={true}
                  isDisabled={btnDisabled === true ? true : false}
                  onClick={loadMoreItems}
                  title={"Load More"}
                />
              </Col>
            </Row>
          )}
        </Container>
      </BgImage>
    </div>
  )
}

export default ImgAndLinkList